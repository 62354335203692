import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styles from './SearchBrand.module.css'
import {
  useGetSparesByBrandCodeMutation,
  useSearchSparesByAnalogueIDMutation,
  useSearchSparesBySpareIDMutation,
} from '../../../../redux/searchSpares/apiSearchSpares'
import Loader from '../../../loader/Loader'
import SearchFinal from '../searchFinal/SearchFinal'
import { checkAllTablesAvailability } from '../../../../utils/checkSparesAvailability'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectSearchedSpare,
  setSearchedFinalSpare,
  setSearchedSpare,
} from '../../../../redux/searchSpares/sparesSlice'
import ModalSpareNotAvailable from '../../../modals/searchSpare/ModalSpareNotAvailable'
import filterString from '../../../../utils/filterString'

const SearchBrand = ({ searchBrand, setSearchBrand }) => {
  const [searchFinal, setSearchFinal] = useState(null)
  const [searchedBrand, setSearchedBrand] = useState(null)
  const [isNoSparesAvailable, setIsNoSparesAvailable] = useState(false)

  const searchInput = useSelector(selectSearchedSpare)

  const dispatch = useDispatch()

  const [
    searchSparesByAnalogueID,
    { isLoading: isLoadingSearchSparesByAnalogueID },
  ] = useSearchSparesByAnalogueIDMutation()
  const [searchSparesBySpareID, { isLoading: isLoadingSearchSparesBySpareID }] =
    useSearchSparesBySpareIDMutation()
  const [searchByBrandCode, { isLoading: isLoadingSearchByBrandCode }] =
    useGetSparesByBrandCodeMutation()

  const handleSearchFinal = async (analogueID, brand, code, spareID) => {
    setSearchedBrand(brand)
    try {
      dispatch(setSearchedFinalSpare({ brand, code, spareID, analogueID }))
      if (analogueID) {
        const response = await searchSparesByAnalogueID({
          brand,
          spareCode: filterString(code),
          analogueID,
        })
        if (checkAllTablesAvailability(response.data, searchInput, brand)) {
          setSearchFinal(response.data)
        } else {
          setIsNoSparesAvailable(true)
        }
      } else if (spareID) {
        const response = await searchSparesBySpareID({
          brand,
          spareCode: filterString(code),
        })
        if (checkAllTablesAvailability(response.data, searchInput, brand)) {
          setSearchFinal(response.data)
        } else {
          setIsNoSparesAvailable(true)
        }
      } else {
        dispatch(setSearchedSpare(filterString(code)))
        const response = await searchByBrandCode({
          brand,
          spareCode: filterString(code),
        })
        if (checkAllTablesAvailability(response.data, searchInput, brand)) {
          setSearchFinal(response.data)
        } else {
          setIsNoSparesAvailable(true)
        }
      }
    } catch (error) {
      toast.error('Сталась помилка...')
    }
  }

  useEffect(() => {
    if (searchBrand && searchBrand.length === 1) {
      handleSearchFinal(
        searchBrand[0].analogueID,
        searchBrand[0].brand,
        filterString(searchBrand[0].code),
        searchBrand[0].spareID
      )
    }
  }, [searchBrand])

  useEffect(() => {
    if (searchBrand && searchBrand.length === 0) {
      setIsNoSparesAvailable(true)
      setSearchBrand(null)
      toast.error('За вашим запитом нічого не знайдено...')
    }
  }, [searchBrand, setSearchBrand])

  if (searchFinal) {
    return (
      <SearchFinal
        handleSearchFinal={handleSearchFinal}
        setSearchFinal={setSearchFinal}
        searchFinal={searchFinal}
        searchedBrand={searchedBrand}
      />
    )
  }

  if (
    isLoadingSearchSparesByAnalogueID ||
    isLoadingSearchSparesBySpareID ||
    isLoadingSearchByBrandCode
  ) {
    return <Loader />
  }

  if (isNoSparesAvailable) {
    return (
      <ModalSpareNotAvailable setNoSparesAvailable={setIsNoSparesAvailable} />
    )
  }

  return (
    <>
      <table className={styles.searchTable}>
        <thead>
          <tr>
            <th className={styles.tableHeader}>Бренд</th>
            <th className={styles.tableHeader}>Номер</th>
            <th className={styles.tableHeader}>Опис</th>
          </tr>
        </thead>
        <tbody>
          {searchBrand &&
            searchBrand.map((el, i) => (
              <tr
                onClick={() =>
                  handleSearchFinal(
                    el.analogueID,
                    el.brand,
                    el.code,
                    el.spareID
                  )
                }
                key={i}
              >
                <td className={styles.tableRowBrand}>{el.brand}</td>
                <td className={styles.tableRowBrand}>{el.code}</td>
                <td className={styles.tableRowBrand}>{el.description}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

export default SearchBrand
